import ProfileSVG from '@clientweb/src/assets/bottomNav/account_circle.svg';
import BoxesSVG from '@clientweb/src/assets/bottomNav/card_giftcard.svg';
import RewardsSVG from '@clientweb/src/assets/bottomNav/stars.svg';
import NavLinkButton from '@clientweb/src/design/v1/general/NavLinkButton/NavLinkButton';
import { memo } from 'react';

const Footer = () => {
    return (
        <div className="flex flex-col sticky bottom-0 justify-center w-screen z-[100]">
            <div id="before-footer-portal" />
            <div
                className={`flex justify-evenly w-full gap-2 pb-2 pt-3 bg-primary-800`}
            >
                <NavLinkButton
                    link="/products"
                    text="Boxes"
                    icon={<BoxesSVG />}
                />
                <NavLinkButton
                    link="/rewards"
                    text="Rewards"
                    icon={<RewardsSVG />}
                />
                <NavLinkButton
                    link="/profile"
                    text="Profile"
                    icon={<ProfileSVG />}
                />
            </div>
            <div id="after-footer-portal" />
        </div>
    );
};

const Memoized = memo(Footer);

export default Memoized;
