import InstagramIcon from '@clientweb/src/assets/icons/instagram-icon.png';
import SnapchatIcon from '@clientweb/src/assets/icons/snap-icon.png';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { memo } from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import styled from 'styled-components';

export interface IShareModalProps {
    className?: string;
    shareUrl: string;
    navigatorShareOptions?: ShareData;
    onClose: () => void;
}

const ShareModal = ({
    className,
    navigatorShareOptions,
    onClose,
    shareUrl,
}: IShareModalProps) => {
    const handleCopy = () => {
        navigator.clipboard
            .writeText(shareUrl)
            .then(() => {
                alert('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    // Open Snapchat share URL
    const handleSnapchatShare = () => {
        window.open(
            `https://www.snapchat.com/scan?attachmentUrl=${encodeURIComponent(
                shareUrl,
            )}`,
            '_blank',
        );
    };

    // Open Instagram app (user will have to share manually)
    const handleInstagramShare = async () => {
        try {
            // Fetch the image and convert it to a Blob

            // Check if sharing is supported and share the file
            if (
                navigator.canShare &&
                navigator.canShare(navigatorShareOptions)
            ) {
                await navigator.share(navigatorShareOptions);
            } else {
                alert('Sharing is not supported on your browser.');
            }
        } catch (err) {
            console.error('Error sharing the image: ', err);
        }
    };

    return (
        <div className={className}>
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Share modal</p>
                        <Button
                            onClick={onClose}
                            variant="delete"
                            aria-label="close"
                        ></Button>
                    </header>
                    <section className="modal-card-body">
                        <p>Share link via</p>
                        <div className="flex flex-row gap-4 mt-4 mb-4 ">
                            <FacebookShareButton url={shareUrl}>
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl}>
                                <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            <WhatsappShareButton url={shareUrl}>
                                <WhatsappIcon size={32} round={true} />
                            </WhatsappShareButton>
                            <button
                                onClick={handleSnapchatShare}
                                className="is-snapchat"
                            >
                                <img
                                    src={SnapchatIcon}
                                    alt="Snapchat"
                                    style={{
                                        width: 32,
                                        height: 32,
                                        borderRadius: '50%',
                                    }}
                                />
                            </button>
                            <button
                                onClick={handleInstagramShare}
                                className="is-instagram"
                            >
                                <img
                                    src={InstagramIcon}
                                    alt="Instagram"
                                    style={{
                                        width: 32,
                                        height: 32,
                                        borderRadius: '50%',
                                    }}
                                />
                            </button>
                        </div>
                        <div>
                            <p>Or copy link</p>
                            <div className="field has-addons">
                                <div className="control is-expanded">
                                    <input
                                        className="input"
                                        type="text"
                                        value={shareUrl}
                                        readOnly // Makes the input non-editable
                                    />
                                </div>
                                <div className="control">
                                    <button
                                        className="button is-info"
                                        onClick={handleCopy}
                                    >
                                        Copy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

const Memoized = memo(ShareModal);
const Styled = styled(Memoized)``;
export default Styled;
