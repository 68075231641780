import { trpcReact } from '@clientweb/src/AppRoot/trpcClient.react';
import Box from '@clientweb/src/components/Box/Box';
import { directusFileUrl, timeout } from '@clientweb/src/utils/frontend';
import { memo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PRODUCT_STATUS } from '@common/business/product/types';

export interface ISingleProductReviewCompleteTransitionScreenProps {
    className?: string;
}

const SingleProductReviewCompleteTransitionScreen = ({
    className,
}: ISingleProductReviewCompleteTransitionScreenProps) => {
    const { productId, campaignId } = useParams();
    const navigate = useNavigate();
    if (!productId) throw new Error('Missing productId');
    if (!campaignId) throw new Error('Missing campaignId');

    const getProductQuery =
        trpcReact.productRouter.v1.getProductForReview.useQuery({
            productId,
            campaignId,
        });
    const getSingleCampaign =
        trpcReact.productRouter.v1.getSingleCampaign.useQuery({
            campaignId,
        });

    const mainThumbnailUrl = directusFileUrl(
        getProductQuery?.data?.main_image,
        { height: '150', width: '150' },
    );

    const handleNextProduct = async () => {
        if (getSingleCampaign.isFetching) return null;
        const remainingProductsToReview =
            getSingleCampaign.data?.products?.filter(
                (item) => item.status !== PRODUCT_STATUS.REVIEW_DONE,
            );
        if (!remainingProductsToReview?.length)
            throw new Error('No next product to review');
        const [nextProduct] = remainingProductsToReview;
        await timeout();
        return navigate(
            `/questionnaire/${campaignId}/${nextProduct?.SampleFestProduct_id?.id}/review`,
        );
    };

    return (
        <div className={`flex flex-col gap-8 text-[#03594D] ${className}`}>
            <div className="flex flex-col justify-center items-center gap-8">
                <div className="flex flex-col justify-center items-center gap-4">
                    <Box className="p-0">
                        <img
                            src={mainThumbnailUrl ?? ''}
                            alt="thumbnail"
                            width={150}
                            height={150}
                        />
                    </Box>
                    <div className="text-center text-[1.5rem]">
                        <span className="capitalize">
                            {getProductQuery.data?.long_name}
                        </span>
                    </div>
                </div>
                <h1 className="text-[38px] text-center leading-[44px] font-bold">
                    Product Review Complete!
                </h1>
                <button
                    onClick={handleNextProduct}
                    className="button bg-[#03594D] text-white font-bold is-fullwidth rounded-[8px] py-4 uppercase text-2xl"
                >
                    Next product
                </button>
                <Link to="/products">
                    <p className="text-[1.5rem] underline text-center leading-[44px]">
                        View all products
                    </p>
                </Link>
            </div>
        </div>
    );
};

const Memoized = memo(SingleProductReviewCompleteTransitionScreen);
const Styled = styled(Memoized)`
    --background: #dee378;
    position: fixed;
    inset: 0;

    display: flex;
    justify-content: center;
    margin: 20px;
    padding: 4rem 2rem;
    border: 2px solid #00787f;
    border-radius: 8px;
    height: 95vh;
    background-color: var(--background);
`;
export default Styled;
