import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import ScanSVG from '@clientweb/src/assets/bottomNav/qr_code_scanner.svg';
import { buttonVariants } from '@clientweb/src/design/v1/general/Button/Button';
import SamplefestLogo from '@clientweb/src/design/v1/general/SamplefestLogo/SamplefestLogo';
import { cn } from '@clientweb/src/utils/frontend';
import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';

import InstallPWAButton from './InstallPWAButton';

export interface IHeaderProps {
    className?: string;
}

const Header = ({ className }: IHeaderProps) => {
    const appRootController = useContext(AppRootContext);

    return (
        <div className="flex flex-col gap-4 p-4">
            <div
                className={cn(
                    'flex gap-2 justify-between items-center',
                    className,
                )}
            >
                <SamplefestLogo classNameRetailer="h-[32px]" />
                <Link
                    to="/scan"
                    className={cn(
                        buttonVariants({ variant: 'primary' }),
                        'flex gap-2 items-center text-base leading-4 flex-1 h-10',
                    )}
                >
                    <ScanSVG />
                    <span className="text-base leading-4 tw-hidden xxs:block">
                        ADD BOX
                    </span>
                </Link>
            </div>
            <div className="flex flex-col gap-1">
                <p className="font-bold text-2xl text-dark font-outfit">{`Greetings ${appRootController?.getUserProfileQuery.data?.firstName}!`}</p>
                <p className="text-base text-shade font-[400]">
                    Review products and receive rewards!
                </p>
            </div>
        </div>
    );
};

const Memoized = memo(Header);
export default Memoized;
