import { trpcReact } from '@clientweb/src/AppRoot/trpcClient.react';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { directusFileUrl } from '@clientweb/src/utils/frontend';
import useModal from '@clientweb/src/utils/react';
import { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import Box from '../Box/Box';
import ShareModal from './ShareModal';

export interface IShareBoxProps {
    className?: string;
}

const ShareBox = ({ className }: IShareBoxProps) => {
    const modalAPI = useModal();
    const shareDataQuery =
        trpcReact.organizationRouter.v1.getShareData.useQuery();
    const [shareFileInstance, setShareFileInstance] = useState<
        File | undefined
    >();

    useEffect(() => {
        (async () => {
            const shareImage = directusFileUrl(
                shareDataQuery.data?.SampleFestConfiguration?.referURL_image,
            );
            if (!shareImage) return undefined;
            const response = await fetch(shareImage);
            const blob = await response.blob();
            const file = new File([blob], 'sample-fest-share.jpg', {
                type: blob.type,
            });
            setShareFileInstance(file);
        })();
    }, [shareDataQuery.data?.SampleFestConfiguration?.referURL_image]);

    const shareUrl = useMemo(() => {
        if (shareDataQuery.data?.SampleFestConfiguration?.refer_url) {
            return shareDataQuery.data?.SampleFestConfiguration?.refer_url;
        }
        return `${window.location.host}/auth/register`;
    }, []);

    const navigatorShareOptions = useMemo((): ShareData => {
        return {
            title:
                shareDataQuery.data?.SampleFestConfiguration?.refer_title ??
                undefined,
            text:
                shareDataQuery.data?.SampleFestConfiguration?.referURL_text ??
                'Missing',
            url: shareUrl,
            files: shareFileInstance ? [shareFileInstance] : undefined,
        };
    }, [
        shareDataQuery.data?.SampleFestConfiguration?.refer_title,
        shareDataQuery.data?.SampleFestConfiguration?.referURL_text,
        shareUrl,
        shareFileInstance,
    ]);

    const handleClick = async () => {
        if (!shareDataQuery) throw new Error(`Missing share data`);
        if (navigator.share) {
            try {
                await navigator.share(navigatorShareOptions);
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            modalAPI.openModal();
        }
    };

    return (
        <Box className={className}>
            <p className="text-[30px] font-bold">Loving Samplefest?</p>
            <Button onClick={handleClick} className="border-[3px]">
                SHARE
            </Button>
            {modalAPI.isOpen ? (
                <ShareModal
                    onClose={modalAPI.closeModal}
                    navigatorShareOptions={navigatorShareOptions}
                    shareUrl={shareUrl}
                />
            ) : null}
        </Box>
    );
};

const Memoized = memo(ShareBox);
const Styled = styled(Memoized)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;
export default Styled;
