import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import { Input } from '@clientweb/src/design/v1/dataEntry/Input/Input';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { firebaseClientAppAuth as auth } from '@clientweb/src/firebase.client';
import { zodResolver } from '@hookform/resolvers/zod';
import { memo, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

interface IProps {
    closeModal: () => void;
    handleError: (field: string) => void;
    handleUpdate: () => void;
}

const userEmailSchema = z.object({
    email: z.string().email(),
});

type FormDataAdditionalInfo = z.infer<typeof userEmailSchema>;

const UserAdditionalInfoForm = ({
    closeModal,
    handleError,
    handleUpdate,
}: IProps) => {
    const appRootControl = useContext(AppRootContext);
    const [error, setError] = useState<string | null>(null);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormDataAdditionalInfo>({
        defaultValues: {
            email: '',
        },
        resolver: zodResolver(userEmailSchema),
    });

    useEffect(() => {
        const user = appRootControl?.getUserProfileQuery.data;
        if (user?.email) {
            setValue('email', user?.email ?? '');
        }
    }, []);

    const onSubmit = async (data: FormDataAdditionalInfo) => {
        const userId = auth.currentUser?.uid;

        if (!userId) {
            setError('User not found');
            return;
        }
        try {
            await trpcVanilla.userRouter.v1.updateUserEmail.mutate({
                userId,
                email: data.email,
            });
        } catch (error) {
            handleError('Email Address');
        }
        handleUpdate();
        appRootControl?.getUserProfileQuery.refetch();
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-1 mt-4"
        >
            <div className="field">
                <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                        <Input type="text" autoComplete="email" {...field} />
                    )}
                />
                {errors.email && (
                    <p className="help is-danger font-outfit">Invalid email</p>
                )}
            </div>

            {error && <p className="help is-danger font-outfit">{error}</p>}

            <div className="flex gap-2">
                <Button type="submit">SAVE</Button>
                <Button type="button" onClick={closeModal} variant="secondary">
                    CANCEL
                </Button>
            </div>
        </form>
    );
};

const Memoized = memo(UserAdditionalInfoForm);
export default Memoized;
