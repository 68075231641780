import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import Box from '@clientweb/src/components/Box/Box';
import FullscreenModal from '@clientweb/src/components/FullscreenModal/FullscreenModal';
import ShareBox from '@clientweb/src/components/ShareBox/ShareBox';
import useRewardController from '@clientweb/src/routes/Rewards/useRewardsController';
import useModal from '@clientweb/src/utils/react';
import { createContext, memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { formatDateToUS } from '@common/utils/dateAndTime/formatting';

export interface IRewardsProps {
    className?: string;
}

export const RewardContext = createContext<ReturnType<
    typeof useRewardController
> | null>(null);

const Rewards = ({ className }: IRewardsProps) => {
    const rewardController = useRewardController();
    const isAnyReward = rewardController?.getUserRewardsQuery?.data?.length;
    if (rewardController?.getUserRewardsQuery?.isFetching)
        return <span>Loading...</span>;
    return (
        <RewardContext.Provider value={rewardController}>
            <div className={className}>
                {isAnyReward ? <RenderRewards /> : <NoRewardsBox />}
                {isAnyReward ? null : <ScanBox />}
                {isAnyReward ? <ShareBox /> : null}
            </div>
        </RewardContext.Provider>
    );
};

const RenderRewards = styled(({ className }) => {
    const rewardsController = useContext(RewardContext);

    return (
        <div className={className}>
            {rewardsController?.getUserRewardsQuery.data
                ?.filter((item) => item.directus?.code?.length)
                ?.map((item) => {
                    const modalAPI = useModal();

                    return (
                        <Box
                            key={item.database.id}
                            className="flex flex-col gap-2 items-center justify-center"
                        >
                            {modalAPI.isOpen ? (
                                <RewardDetailsModal
                                    reward={item}
                                    onClose={modalAPI.closeModal}
                                />
                            ) : null}
                            <span className="text-2xl font-bold">
                                Congratulations!
                            </span>
                            {item.directus?.code ? (
                                <div className="flex flex-col gap-4">
                                    <span>
                                        You’ve earned &nbsp;
                                        {item.directus?.code}
                                    </span>
                                    <button
                                        onClick={modalAPI.openModal}
                                        className="button bg-[#03594D] text-white font-bold is-fullwidth rounded-[8px] py-4 uppercase text-xl"
                                    >
                                        View Details
                                    </button>
                                </div>
                            ) : (
                                <span>
                                    You were too late. Maybe next time lol
                                </span>
                            )}
                        </Box>
                    );
                })}
        </div>
    );
})`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${Box} {
        background-color: #dee378;
    }
`;

const RewardDetailsModal = styled(
    ({
        className,
        reward,
        onClose,
    }: {
        className?: string;
        onClose: () => void;
        reward?: Awaited<
            ReturnType<typeof trpcVanilla.productRouter.v1.getUserRewards.query>
        >[number];
    }) => {
        const handleCopy = () => {
            if (!reward?.directus?.code) return;
            navigator.clipboard
                .writeText(reward?.directus?.code)
                .then(() => {
                    alert('Copied to clipboard!');
                })
                .catch((err) => {
                    console.error('Failed to copy: ', err);
                });
        };

        return (
            <div className={className}>
                <FullscreenModal>
                    <div className="flex flex-col gap-4 items-center justify-center">
                        <span className="text-2xl font-bold text-center">
                            {reward?.directus?.reward_template?.name}
                        </span>
                        <span className="text-center">
                            Dui neque senectus amet faucibus molestie diam enim.
                            Tempus faucibus lectus ut ultrices sed fermentum
                            nulla amet. Consequat cras et nulla tortor arcu
                            risus euismod. Quis imperdiet rutrum vitae
                            adipiscing leo urna
                        </span>
                        <span className="font-bold">
                            Expires:&nbsp;
                            {formatDateToUS(
                                reward?.directus?.reward_template?.expires,
                            )}
                        </span>

                        <div className="field has-addons">
                            <div className="control is-expanded">
                                <input
                                    className="input border-2 border-[#004D40] p-2 mb-5 placeholder-inherit"
                                    disabled
                                    type="text"
                                    value={reward?.directus?.code ?? ''}
                                    readOnly // Makes the input non-editable
                                />
                            </div>
                            <div className="control">
                                <button
                                    className="button is-info"
                                    onClick={handleCopy}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                        <button
                            onClick={() => onClose()}
                            className="button bg-[#03594D] text-white font-bold is-fullwidth rounded-[8px] py-4 uppercase text-2xl"
                        >
                            Back to Rewards
                        </button>
                        <span className="underline">Terms and conditions</span>
                        {/* Expand */}
                    </div>
                </FullscreenModal>
            </div>
        );
    },
)``;

const ScanBox = styled(({ className }) => {
    return (
        <div className={className}>
            <Link to="/scan">
                <button
                    style={{ borderWidth: 3 }}
                    className="button text-[#03594D] border-[#03594D] font-bold is-fullwidth rounded-[8px] py-4 uppercase text-2xl"
                >
                    Ready to Scan
                </button>
            </Link>
        </div>
    );
})``;

const NoRewardsBox = styled(({ className }) => {
    return (
        <div className={className}>
            <Box className="flex flex-col justify-center items-center gap-5">
                <p className="text-[30px] font-bold">Nothing yet...</p>
                <p className="max-w-[95%] text-center text-[1.5rem] mb-2">
                    Once you&apos;ve reviewed all the products in your
                    Samplefest box, you&apos;ll get your reward!
                </p>
                <Link to="/products">
                    <button className="button bg-[#03594D] text-white font-bold is-fullwidth rounded-[8px] py-4 uppercase text-2xl">
                        Go to Products
                    </button>
                </Link>
            </Box>
        </div>
    );
})``;

const Memoized = memo(Rewards);
const Styled = styled(Memoized)`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
export default Styled;
