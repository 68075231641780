import Footer from '@clientweb/src/components/Footer/Footer';
import Header from '@clientweb/src/components/Header/Header';
import React, { memo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import CampaignLevelQuestionBanner from './CampaignLevelQuestionBanner';
import CampaignQuestionPopUp from './CampaignQuestionPopUp';
import useDefaultLayoutController from './useDefaultLayoutController';

export interface IDefaultLayoutProps {
    className?: string;
    children?: React.ReactNode;
}

const DefaultLayout = ({ className, children }: IDefaultLayoutProps) => {
    const defaultLayoutController = useDefaultLayoutController();

    return (
        <div className={className}>
            <Header />
            <MainBody>{children}</MainBody>
            <Footer />

            {defaultLayoutController.activeCampaignQuestionarieQuery?.data
                ?.campaign &&
            defaultLayoutController.portalTarget &&
            !defaultLayoutController.isCampaignLevelBannerDismissed
                ? createPortal(
                      <CampaignLevelQuestionBanner
                          onConfirm={() =>
                              defaultLayoutController.onTriggerCampaignLevelModal()
                          }
                      />,
                      defaultLayoutController.portalTarget,
                  )
                : null}

            {defaultLayoutController.activeCampaignQuestionarieQuery?.data
                ?.campaign &&
            defaultLayoutController.campaignQuestionModal.isOpen ? (
                <CampaignQuestionPopUp
                    campaignId={
                        defaultLayoutController.activeCampaignQuestionarieQuery
                            ?.data?.campaign?.id ?? 'missing'
                    }
                    onClose={
                        defaultLayoutController.campaignQuestionModal.closeModal
                    }
                />
            ) : null}
        </div>
    );
};

const MainBody = styled.div`
    padding: 20px;
    background-color: rgba(230, 238, 237, 1);
`;

const Memoized = memo(DefaultLayout);
const Styled = styled(Memoized)`
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: rgba(230, 238, 237, 1);
`;
export default Styled;
