import { memo } from 'react';
import styled from 'styled-components';

export interface IFullscreenModalProps {
    className?: string;
    children?: React.ReactNode;
}

const FullscreenModal = ({ className, children }: IFullscreenModalProps) => {
    return (
        <div className={`${className}`}>
            <div className={`mainModal`}>{children}</div>
        </div>
    );
};

const Memoized = memo(FullscreenModal);
const Styled = styled(Memoized)`
    position: fixed;
    left: 0;
    top: 0;
    background-color: white;
    width: 100vw;
    height: 100vh;
    z-index: 999;

    .mainModal {
        --background: #dee378;
        position: fixed;
        inset: 0;

        display: flex;
        justify-content: center;
        margin: 20px;
        padding: 4rem 2rem;
        border: 2px solid #00787f;
        border-radius: 8px;
        height: 95vh;
        background-color: var(--background);
    }
`;
export default Styled;
