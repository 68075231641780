import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import { Input } from '@clientweb/src/design/v1/dataEntry/Input/Input';
import Select from '@clientweb/src/design/v1/dataEntry/Select/Select';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { firebaseClientAppAuth as auth } from '@clientweb/src/firebase.client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { CRM_STATE_LIST } from '@common/commonData/crmStateList';

const userRegisterSchema = z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    addressLine1: z.string().min(1),
    addressLine2: z.string(),
    city: z.string().min(1),
    state: z.string().min(1),
    zipCode: z.string().min(1),
});

type FormDataUserRegister = z.infer<typeof userRegisterSchema>;

const UserRegister = ({
    setIsRegisterFinished,
}: {
    setIsRegisterFinished: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const appRootControl = useContext(AppRootContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormDataUserRegister>({
        defaultValues: {
            firstName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
        },
        resolver: zodResolver(userRegisterSchema),
    });

    const onSubmit = async (data: FormDataUserRegister) => {
        setIsLoading(true);
        const userId = auth.currentUser?.uid;

        if (!userId) {
            setError('User not found');
            return;
        }

        await trpcVanilla.userRouter.v1.updateUserCredentials.mutate({
            userId,
            firstName: data.firstName,
            lastName: data.lastName,
        });

        const user = await trpcVanilla.userRouter.v1.get.query({
            firebaseId: auth.currentUser?.uid,
        });

        if (!user) {
            setError('User not found');
            return;
        }

        await trpcVanilla.userRouter.v1.createUserAddress.mutate({
            userId: user.id,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            zipCode: data.zipCode,
            state: data.state,
        });
        setIsLoading(false);
        appRootControl?.getUserProfileQuery.refetch();
        setIsRegisterFinished(true);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 mt-5"
        >
            <div className="flex gap-6">
                <div className="field">
                    <label
                        htmlFor="firstName"
                        className="text-primary-800 font-bold"
                    >
                        First Name
                    </label>
                    <Controller
                        control={control}
                        name="firstName"
                        render={({ field }) => (
                            <Input
                                type="text"
                                isError={!!errors.firstName}
                                autoComplete="given-name"
                                {...field}
                            />
                        )}
                    />
                    {errors.firstName && (
                        <p className="help is-danger font-outfit">
                            First Name is required
                        </p>
                    )}
                </div>

                <div className="field">
                    <label
                        htmlFor="lastName"
                        className="text-primary-800 font-bold"
                    >
                        Last Name
                    </label>
                    <Controller
                        control={control}
                        name="lastName"
                        render={({ field }) => (
                            <Input
                                type="text"
                                isError={!!errors.lastName}
                                autoComplete="family-name"
                                {...field}
                            />
                        )}
                    />
                    {errors.lastName && (
                        <p className="help is-danger font-outfit">
                            Last Name is required
                        </p>
                    )}
                </div>
            </div>
            <div className="field">
                <label
                    htmlFor="addressLine1"
                    className="text-primary-800 font-bold"
                >
                    Address Line 1
                </label>
                <Controller
                    control={control}
                    name="addressLine1"
                    render={({ field }) => (
                        <Input
                            type="text"
                            isError={!!errors.addressLine1}
                            autoComplete="address-line1"
                            {...field}
                        />
                    )}
                />
                {errors.addressLine1 && (
                    <p className="help is-danger font-outfit">
                        Address Line 1 is required
                    </p>
                )}
            </div>

            <div className="field">
                <label
                    htmlFor="addressLine2"
                    className="text-primary-800 font-bold"
                >
                    Address Line 2
                </label>
                <Controller
                    control={control}
                    name="addressLine2"
                    render={({ field }) => (
                        <Input
                            type="text"
                            isError={!!errors.addressLine2}
                            autoComplete="address-line2"
                            {...field}
                        />
                    )}
                />
                {errors.addressLine2 && (
                    <p className="help is-danger font-outfit">
                        Address Line 2 is required
                    </p>
                )}
            </div>

            <div className="field">
                <label htmlFor="city" className="text-primary-800 font-bold">
                    City
                </label>
                <Controller
                    control={control}
                    name="city"
                    render={({ field }) => (
                        <Input
                            type="text"
                            autoComplete="city"
                            {...field}
                            isError={!!errors.city}
                        />
                    )}
                />
                {errors.city && (
                    <p className="help is-danger font-outfit">
                        City is required
                    </p>
                )}
            </div>
            <div className="flex gap-4">
                <div className="field ">
                    <label
                        htmlFor="state"
                        className="text-primary-800 font-bold"
                    >
                        State
                    </label>
                    <Controller
                        control={control}
                        name="state"
                        render={({ field }) => (
                            <Select
                                {...field}
                                autoComplete="state"
                                isError={!!errors.state}
                                placeholder="State"
                            >
                                {CRM_STATE_LIST.map((state) => (
                                    <option
                                        key={state.stateKey}
                                        value={state.stateKey}
                                    >
                                        {state.fullName}
                                    </option>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.city && (
                        <p className="help is-danger font-outfit">
                            City is required
                        </p>
                    )}
                </div>
                <div className="field w-[40%]">
                    <label
                        htmlFor="zipCode"
                        className="text-primary-800 font-bold"
                    >
                        Zip
                    </label>
                    <Controller
                        control={control}
                        name="zipCode"
                        render={({ field }) => (
                            <Input
                                type="text"
                                isError={!!errors.zipCode}
                                autoComplete="postal-code"
                                {...field}
                            />
                        )}
                    />
                    {errors.zipCode && (
                        <p className="help is-danger font-outfit">
                            Zip code is required
                        </p>
                    )}
                </div>
            </div>
            <Button type="submit" className="mt-auto" isLoading={isLoading}>
                OPEN BOX
            </Button>
            {error && <p className="help is-danger font-outfit">{error}</p>}
        </form>
    );
};

export default UserRegister;
