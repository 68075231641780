import {
    AppRootContext,
    DEFAULT_TRANSITION_TIMEOUT,
} from '@clientweb/src/AppRoot/useAppRootController';
import Face from '@clientweb/src/assets/face.svg';
import { memo, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export interface ITransitionScanSuccessfulProps {
    className?: string;
}

const TransitionScanSuccessful = ({
    className,
}: ITransitionScanSuccessfulProps) => {
    const appRootController = useContext(AppRootContext);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/products');
        }, DEFAULT_TRANSITION_TIMEOUT);
    }, [navigate]);

    return (
        <div className={`flex flex-col gap-8 text-[#03594D] ${className}`}>
            <div className="flex flex-col justify-center items-center gap-8">
                <div className="flex flex-col justify-center items-center gap-4">
                    <img src={Face} alt="avatar" width={72} height={72} />
                    <div className="text-center text-[1.5rem]">
                        <span className="capitalize">
                            {
                                appRootController?.getUserProfileQuery.data
                                    ?.firstName
                            }
                            &nbsp;
                            {
                                appRootController?.getUserProfileQuery.data
                                    ?.lastName
                            }
                        </span>
                    </div>
                </div>
                <h1 className="text-[38px] text-center leading-[44px] font-bold">
                    [Ooh, that’s a Good One!]
                </h1>
                <p className="text-[1.5rem] text-center leading-[44px] font-bold ">
                    Loading Samplefest box...
                </p>
            </div>
        </div>
    );
};

const Memoized = memo(TransitionScanSuccessful);
const Styled = styled(Memoized)`
    --background: #dee378;
    position: fixed;
    inset: 0;

    display: flex;
    justify-content: center;
    margin: 20px;
    padding: 4rem 2rem;
    border: 2px solid #00787f;
    border-radius: 8px;
    height: 95vh;
    background-color: var(--background);
`;
export default Styled;
