import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import Brandmark from '@clientweb/src/assets/Samplefest Brandmark.svg';
import Face from '@clientweb/src/assets/face.svg';
import { buttonVariants } from '@clientweb/src/design/v1/general/Button/Button';
import {
    REDIRECT_AFTER_LOGIN,
    REDIRECT_AFTER_NOT_AUTHORIZED,
} from '@clientweb/src/routes/Router';
import { LOCAL_STORAGE_KEYS } from '@clientweb/src/utils/localstorage';
import { getAuth } from 'firebase/auth';
import { memo, useContext, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

export interface ILoginSuccessProps {
    className?: string;
}

const LoginSuccess = ({ className }: ILoginSuccessProps) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const campaignShortCode = localStorage.getItem(
        LOCAL_STORAGE_KEYS.SCANNED_QR_CODE_CAMPAIGN_SHORT_CODE,
    );
    const auth = getAuth();
    const appRootController = useContext(AppRootContext);

    useEffect(() => {
        let redirectTimeout: NodeJS.Timeout;
        if (!auth.currentUser) {
            navigate(REDIRECT_AFTER_NOT_AUTHORIZED);
        }
        if (campaignShortCode) {
            redirectTimeout = setTimeout(() => {
                navigate(REDIRECT_AFTER_LOGIN);
            }, 1000);
        }

        return () => clearTimeout(redirectTimeout);
    }, []);

    return (
        <div
            className={`absolute inset-0 h-screen bg-secondary px-8 xs:!px-24 flex flex-col items-center gap-6 justify-center ${className}`}
        >
            <img src={Face} alt="avatar" width={72} height={72} />
            <p className="text-primary-800 font-bold text-[1.5rem]">
                <span className="capitalize">
                    {appRootController?.getUserProfileQuery.data?.firstName}
                </span>
                &nbsp;
                <span className="capitalize">
                    {appRootController?.getUserProfileQuery.data?.lastName}
                </span>
            </p>
            <img src={Brandmark} alt="samplefest brandmark" />
            <h2 className="text-[38px] leading-[44px] text-center text-primary-800 font-outfit font-bold	">
                {searchParams.get('register')
                    ? 'SIGN UP COMPLETE!'
                    : 'LOGIN SUCCESSFUL!'}
            </h2>
            {!campaignShortCode ? (
                <>
                    <Link
                        to="/profile"
                        className={buttonVariants({ variant: 'primary' })}
                    >
                        GO TO PROFILE
                    </Link>
                    <Link
                        to="/scan"
                        className={buttonVariants({ variant: 'primary' })}
                    >
                        SCAN QR CODE
                    </Link>
                </>
            ) : (
                <p className="text-2xl text-center text-primary-800 font-medium	font-outfit uppercase">
                    Loading Samplefest box...
                </p>
            )}
        </div>
    );
};

const Memoized = memo(LoginSuccess);
const Styled = styled(Memoized)``;
export default Styled;
