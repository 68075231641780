import avatar from '@clientweb/src/assets/avatar.svg';
import tile1 from '@clientweb/src/assets/grid/2-1.svg';
import tile2 from '@clientweb/src/assets/grid/2-2.svg';
import tile3 from '@clientweb/src/assets/grid/2-3.svg';
import UserAddressForm from '@clientweb/src/components/Forms/UserAddress';
import UserCredentialForm from '@clientweb/src/components/Forms/UserCredentials';
import UserDOBForm from '@clientweb/src/components/Forms/UserDOB';
import UserEmailForm from '@clientweb/src/components/Forms/UserEmail';
import UserPronounForm from '@clientweb/src/components/Forms/UserPronoun';
import UserInfo from '@clientweb/src/routes/Profile/UserInfo';
import { enqueueSnackbar, SnackbarKey } from 'notistack';
import { act, memo } from 'react';
import styled from 'styled-components';

import useProfileController from './useProfileController';

export interface IProfileProps {
    className?: string;
}

const Profile = ({ className }: IProfileProps) => {
    const { editedField, openModal, closeModal } = useProfileController();

    const handleError = (field: string) => {
        closeModal();
        enqueueSnackbar(`${editedField} not updated`, {
            action: () => {
                return (
                    <button
                        className="text-white"
                        onClick={() => openModal(field)}
                    >
                        Retry
                    </button>
                );
            },
            variant: 'error',
        });
    };

    const handleUpdate = () => {
        closeModal();
        enqueueSnackbar({
            message: `${editedField} updated`,
            autoHideDuration: 2500,
        });
    };
    const editForm = (() => {
        switch (editedField) {
            case 'Name':
                return (
                    <UserCredentialForm
                        closeModal={closeModal}
                        handleUpdate={handleUpdate}
                        handleError={handleError}
                    />
                );
            case 'Email Address':
                return (
                    <UserEmailForm
                        closeModal={closeModal}
                        handleUpdate={handleUpdate}
                        handleError={handleError}
                    />
                );
            case 'Address':
                return (
                    <UserAddressForm
                        closeModal={closeModal}
                        handleUpdate={handleUpdate}
                        handleError={handleError}
                    />
                );
            case 'Date of Birth':
                return (
                    <UserDOBForm
                        closeModal={closeModal}
                        handleUpdate={handleUpdate}
                        handleError={handleError}
                    />
                );
            case 'Pronouns':
                return (
                    <UserPronounForm
                        closeModal={closeModal}
                        handleUpdate={handleUpdate}
                        handleError={handleError}
                    />
                );
            default:
                return null;
        }
    })();

    return (
        <>
            <div className={`flex flex-col gap-4 ${className}`}>
                <div className="relative flex overflow-hidden mx-auto">
                    <img src={tile1} alt="tile" className=" opacity-65" />
                    <img src={tile2} alt="tile" className=" opacity-65" />
                    <img src={tile3} alt="tile" className=" opacity-65" />
                    <div className="absolute bg-white p-3 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-[88px] rounded-full">
                        <img
                            src={avatar}
                            alt="avatar"
                            className="w-full h-full"
                        />
                    </div>
                </div>
                <UserInfo setEditedField={openModal} />
            </div>
            {editedField !== '' && (
                <div className="fixed overflow-hidden h-screen w-screen grid place-items-center inset-0 bg-primary-overlay z-[103]">
                    <div className="bg-white rounded-md p-4 flex flex-col shadow-modal w-[95%] max-w-[400px]">
                        <h3 className="font-bold text-2xl font-outfit text-dark">
                            Edit {editedField}
                        </h3>
                        {editForm}
                    </div>
                </div>
            )}
        </>
    );
};

const Memoized = memo(Profile);
const Styled = styled(Memoized)``;
export default Styled;
