import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import CrossIcon from '@clientweb/src/assets/bottomNav/cross-no-border.svg';
import InfoIcon from '@clientweb/src/assets/icons/info.svg';
import FullscreenLoader from '@clientweb/src/components/FullscreenLoader/FullscreenLoader';
import QuestionnaireBottomNav from '@clientweb/src/routes/Questionnaire/ReviewProduct/QuestionnaireBottomNav';
import ReviewQuestion from '@clientweb/src/routes/Questionnaire/ReviewProduct/Questions/ReviewQuestion';
import { directusFileUrl } from '@clientweb/src/utils/frontend';
import { memo, useContext } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ProductDetails from './ProductDetails';
import { ReviewContext } from './ReviewProduct';

export interface IReviewStepProps {
    className?: string;
}

const ReviewStep = ({ className }: IReviewStepProps) => {
    const reviewController = useContext(ReviewContext);

    if (!reviewController?.isDataFetched) return <FullscreenLoader />;

    return (
        <div className={className}>
            <div className="flex justify-between items-center py-3 px-5 border-b border-shade-100">
                {reviewController?.productId ? (
                    <ProductHeader />
                ) : (
                    <CampaignHeader />
                )}
            </div>
            <ReviewQuestion className="mt-4 px-5" />
            <QuestionnaireBottomNav />
        </div>
    );
};

const ProductHeader = styled(({ className }) => {
    const reviewController = useContext(ReviewContext);

    return (
        <div className={className}>
            <div className="flex items-center gap-3">
                <Link to="/products" className="size-[14px]">
                    <CrossIcon />
                </Link>
                <img
                    src={
                        directusFileUrl(reviewController?.thumbnailUrl, {
                            height: '48',
                            width: '72',
                        }) ?? ''
                    }
                    width={72}
                    height={48}
                    alt="thumbnail"
                    className="rounded-md"
                />
                <span className="font-bold text-xl text-dark">
                    {reviewController?.getProductQuery?.data?.long_name}
                </span>
            </div>
            <img
                src={InfoIcon}
                alt="info icon"
                onClick={() => {
                    reviewController?.productDetailsSection.openModal();
                }}
            />
            {reviewController?.productDetailsSection.isOpen && (
                <ProductDetails />
            )}
        </div>
    );
})`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const CampaignHeader = styled(({ className }) => {
    const appRootController = useContext(AppRootContext);

    return (
        <div className={className}>
            <div className="flex items-center gap-3">
                <img
                    src={appRootController?.whiteLabelLogoUrl}
                    className="size-10 xxs:size-14 -mr-2 z-20"
                    alt="retailer-logo"
                />
                <Link to="/products">
                    <p className="text-primary-800 font-medium font-outfit absolute top-[20px] right-[20px]">
                        Skip
                    </p>
                </Link>
            </div>
        </div>
    );
})``;

const CAROUSEL_RESPONSIVE_SETUP = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

export const ProductCarousel = styled(
    ({ className }: { className?: string }) => {
        const reviewController = useContext(ReviewContext);
        const images = (
            reviewController?.getProductQuery?.data?.images ?? []
        )?.map((item) => {
            const fileUrl = directusFileUrl(item?.directus_files_id);
            return fileUrl;
        });

        return (
            <div className={className}>
                <Carousel
                    showDots
                    arrows={false}
                    ssr={false}
                    infinite
                    customTransition="all 1s"
                    responsive={CAROUSEL_RESPONSIVE_SETUP}
                    itemClass="product-carousel-item"
                    customDot={<CustomDot />}
                >
                    {images?.map((image) => {
                        return (
                            <div key={image}>
                                <img
                                    alt="product thumbnail"
                                    src={image ?? ''}
                                    className="w-full h-[280px] rounded-md"
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        );
    },
)`
    padding: 0;
    width: 100%;
    height: 280px;

    .product-carousel-item {
        overflow: hidden;
    }

    .react-multi-carousel-dot-list {
        background-color: rgba(10, 10, 10, 1);
        width: fit-content;
        padding: 8px;
        border-radius: 24px;
        bottom: 10px;
        margin-left: auto;
        margin-right: 10px;
        gap: 6px;
        .react-multi-carousel-dot {
        }
    }
`;

const CustomDot = styled(({ className, onClick }) => {
    return <div className={className} onClick={onClick} />;
})`
    width: 8px;
    height: 8px;
    background-color: rgba(168, 167, 168, 1);
    border-radius: 100%;
    cursor: pointer;

    ${({ active }) =>
        active
            ? `
    background-color: #fff;
    `
            : ``}
`;

const Memoized = memo(ReviewStep);
const Styled = styled(Memoized)`
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
`;
export default Styled;
