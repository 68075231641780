import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
        --bulma-family-primary: "Roboto","ui-monospace","Inter var", sans-serif;
    }

    .font-bold {
        font-family: "Outfit", sans-serif !important;
    }
`;

export default GlobalStyle;
