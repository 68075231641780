import CrossIcon from '@clientweb/src/assets/bottomNav/cross-no-border.svg';
import Tabs from '@clientweb/src/components/Tabs/Tabs';
import { useContext } from 'react';

import { ReviewContext } from './ReviewProduct';
import { ProductCarousel } from './ReviewStep';

const ProductDetails = () => {
    const reviewController = useContext(ReviewContext);

    return (
        <div className="absolute inset-0 bg-primary-overlay z-[101] pt-[120px]">
            <div className="absolute flex flex-col gap-4 p-5 bg-white z-[102] w-full h-full rounded-t-md">
                <div className="flex justify-between items-center">
                    <h3 className="text-xl font-bold text-dark">
                        {reviewController?.getProductQuery?.data?.short_name}
                    </h3>
                    <button
                        className="text-dark"
                        onClick={
                            reviewController?.productDetailsSection.closeModal
                        }
                    >
                        <CrossIcon />
                    </button>
                </div>
                <ProductCarousel />
                <Tabs />
            </div>
        </div>
    );
};

export default ProductDetails;
