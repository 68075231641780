import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

/**
 *
 * @param date if Number = timestame
 * @returns
 */
export const getTimeInMinessota = (
    date: Date | number | null | undefined = new Date(),
    format?: string,
): string => {
    const forSureDate = ((): Date => {
        if (Number.isInteger(date))
            return convertUnixTimestampToDate(date as number);
        return date as Date;
    })();
    const minnesotaTime = formatInTimeZone(
        forSureDate,
        'America/Chicago',
        format ?? 'yyyy-MM-dd HH:mm:ss zzz',
    );
    return minnesotaTime;
};

export const convertUnixTimestampToInMinnesotaDate = (unixTimestamp?: number) =>
    getTimeInMinessota(unixTimestamp);

export const convertUnixTimestampToDate = (unixTimestamp?: number) => {
    if (!unixTimestamp) return new Date(0);
    const milliseconds = unixTimestamp * 1000;
    return new Date(milliseconds);
};

export const convertDateToUnixTimestamp = (date: Date): number => {
    return Math.floor(date.getTime() / 1000);
};

export const convertUnixTimestampToFormattedDate = (
    unixTimestamp: number,
    revert = false, // to handle default value of date input
): string => {
    const date = new Date(unixTimestamp * 1000);
    const day = ('0' + date.getDate()).slice(-2); // add leading 0 for single digit date
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // add leading 0 for single digit month
    const year = date.getFullYear();
    return revert ? `${year}-${month}-${day}` : `${day}/${month}/${year}`;
};

export const formatPriceToUS = (price: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(price);
};

export const formatDateToUS = (date?: Date): string => {
    if (!date) return '';
    return format(date, 'MM/dd/yyyy hh:mm a');
};
