import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { REDIRECT_AFTER_LOGIN } from '@clientweb/src/routes/Router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const AuthRoute = () => {
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            const backendUser = await trpcVanilla.userRouter.v1.get.query({
                firebaseId: user?.uid,
            });
            if (
                user &&
                backendUser &&
                window.location.pathname !== '/transition/login-success'
            ) {
                navigate(REDIRECT_AFTER_LOGIN);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return <Outlet />;
};

export default AuthRoute;
