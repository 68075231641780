import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import { Input } from '@clientweb/src/design/v1/dataEntry/Input/Input';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { firebaseClientAppAuth as auth } from '@clientweb/src/firebase.client';
import { zodResolver } from '@hookform/resolvers/zod';
import { memo, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
    convertDateToUnixTimestamp,
    convertUnixTimestampToFormattedDate,
} from '@common/utils/dateAndTime/formatting';

interface IProps {
    closeModal: () => void;
    handleError: (field: string) => void;
    handleUpdate: () => void;
}

const userDOBSchema = z.object({
    dateOfBirth: z.string(),
});

type FormDataDOB = z.infer<typeof userDOBSchema>;

const UserAdditionalInfoForm = ({
    closeModal,
    handleError,
    handleUpdate,
}: IProps) => {
    const appRootControl = useContext(AppRootContext);
    const [error, setError] = useState<string | null>(null);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormDataDOB>({
        defaultValues: {
            dateOfBirth: '',
        },
        resolver: zodResolver(userDOBSchema),
    });

    useEffect(() => {
        const user = appRootControl?.getUserProfileQuery.data;
        if (user?.dateOfBirth) {
            setValue(
                'dateOfBirth',
                convertUnixTimestampToFormattedDate(user.dateOfBirth, true) ??
                    '',
            );
        }
    }, [appRootControl?.getUserProfileQuery.data]);

    const onSubmit = async (data: FormDataDOB) => {
        const userId = auth.currentUser?.uid;

        if (!userId) {
            setError('User not found');
            return;
        }
        try {
            await trpcVanilla.userRouter.v1.updateUserDOB.mutate({
                userId,
                dateOfBirth: convertDateToUnixTimestamp(
                    new Date(data.dateOfBirth),
                ),
            });
        } catch (error) {
            handleError('Date of Birth');
        }
        handleUpdate();
        appRootControl?.getUserProfileQuery.refetch();
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-1 mt-4"
        >
            <div className="field">
                <Controller
                    control={control}
                    name="dateOfBirth"
                    render={({ field }) => (
                        <Input
                            type="date"
                            autoComplete="bday"
                            placeholder="DOB 00/00/0000"
                            {...field}
                        />
                    )}
                />
                {errors.dateOfBirth && (
                    <p className="help is-danger font-outfit">Invalid DOB</p>
                )}
            </div>
            {error && <p className="help is-danger font-outfit">{error}</p>}
            <div className="flex gap-2">
                <Button type="submit">SAVE</Button>
                <Button type="button" onClick={closeModal} variant="secondary">
                    CANCEL
                </Button>
            </div>
        </form>
    );
};

const Memoized = memo(UserAdditionalInfoForm);
export default Memoized;
